import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, CircularProgress } from "@mui/material";
import { useRouter } from "next/router";
import en from "../../../locales/en";
import ar from "../../../locales/ar";
import { styless } from "./user_dropDown";
import { KeyboardArrowDown } from "@mui/icons-material";
import Popover from "@mui/material/Popover";
import FlyingCard2 from "@/components/mini-components/FlyingCard2";
import { useDispatch, useSelector } from "react-redux";
import { userAction } from "@/Redux/store";
import jsonwebtoken from "jsonwebtoken";
import { api } from "@/utils/Axios";
import { EnvKey } from "@/utils/const";
import { FetchUserData } from "@/utils/Fetch_userDataFn";
import StarIcon from "@mui/icons-material/Star";
export default function UserDropDown({
  setState,
  Login,
  SetLogin,
  setArrDirA,
  LogOut,
}) {
  const router = useRouter();
  const { locale } = router;
  const name = useSelector((state) => state.user.name);
  const userId = useSelector((state) => state.user.id);
  const nationalID = useSelector((state) => state.user.nationalId);
  const avatar = useSelector((state) => state.user.avatar);
  const totalPoints = useSelector((state) => state.user.wallet.TotalPoints);
  const dispatch = useDispatch();
  const [Data, setData] = useState("");
  const [Loading, SetLoading] = useState(false);
  useEffect(() => {
    if (!totalPoints && userId) {
      nationalID&&api.get(`/wallets/${userId}`).then((res) => {
        dispatch(userAction.update({ key: "wallet", val: res.data }));
      });
    }
  }, [userId]);
  const FetchData = async () => {
    let SessionData = jsonwebtoken.verify(
      localStorage.getItem("UT_UI"),
      EnvKey.secretKey
    );
    let UserID = SessionData.id;
    FetchUserData(UserID, SetLoading, setData);
  };
  useEffect(() => {
    if (localStorage.getItem("UT_UI")) {
      if ((name == "" || avatar == "") && router.pathname !== "/contract") {
        FetchData();
      }
    }
  }, [name]);

  const t = locale === "en" ? en : ar;
  const userData = {
    image: avatar ? avatar : Data.avatar,
    user_name: name ? name?.split(" ")[0] : Data.name?.split(" ")[0],
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const storedLocale = sessionStorage.getItem("lang") || locale;
    if (storedLocale && storedLocale !== locale) {
      replaceLanguage(storedLocale);
    }
  }, [locale]);

  const replaceLanguage = (newLocale) => {
    sessionStorage.setItem("lang", newLocale);
    const { pathname, query } = router;
    router.replace({ pathname: `/${newLocale}${pathname}`, query }, undefined, {
      locale: newLocale,
    });
  };

  const toAr = () => replaceLanguage("ar");
  const toEn = () => replaceLanguage("en");

  return (
    <>
      <Button
        aria-label="user dropdown button"
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
        sx={{
          ...styless.dropBox,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.25rem",
          }}
        >
          {}
          <Avatar
            src={userData.image}
            alt="User Avatar"
            sx={{ width: 24, height: 24 }}
          />
          <Box
            sx={{
              fontWeight: "700",
            }}
          >
            {!Loading ? (
              userData.user_name
            ) : (
              <CircularProgress
                color="inherit"
                size={15}
                sx={{ mt: 1 }}
              ></CircularProgress>
            )}
          </Box>
          <KeyboardArrowDown />
        </Box>
      </Button>
      {totalPoints != null &&
        (router.asPath === "/contract" ||
          router.asPath === "/customerservices" ||
          router.asPath === "/profile") && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              mt: "4px",
              justifyContent: "center",
            }}
          >
            <>
              <StarIcon sx={{ color: "#2D317F", fontSize: "15px" }} />
              <Box sx={{ fontSize: "13px" }}>
                {`${totalPoints} ${t.userNav.loyaltyPoints}`}
              </Box>
            </>
          </Box>
        )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ mt: 0.7 }}
      >
        <FlyingCard2
          setState={setState}
          setAnchorEl={setAnchorEl}
          toAr={toAr}
          toEn={toEn}
          LogOut={LogOut}
        ></FlyingCard2>
      </Popover>
    </>
  );
}
