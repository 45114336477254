import { colors } from "../utils/const";

export const styles = {
  container: (theme) => ({
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    flexWrap: "wrap",
    // gap: 2,
    gap: { xs: "10px", xl: "24px" },
    color: colors.blue,
    pt: 2,
    pb: 2,
    bgcolor: colors.white,
    fontSize: 30,
  }),
  tab: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: colors.navy_blue,
    // fontSize: 15,
    fontSize: "14px",
    textDecoration: "none",
    transition: "1s",
    "&:hover": {
      color: colors.navy_blue,
    },
  },
  loginBtn: {
    color: colors.blue,
    border: `1px solid ${colors.blue}`,
    backgroundColor: colors.white,
    borderRadius: "8px",
    // ml: 2,
    textTransform: "initial",
    fontSize: "14px",
    "&:hover": {
      border: `1px solid ${colors.blue}`,
    },
  },
  downloadBtn: (theme) => ({
    color: colors.white,
    border: `1px solid ${colors.blue}`,
    backgroundColor: colors.blue,
    borderRadius: "8px",
    textTransform: "initial",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: colors.blue,
      color: colors.white,
    },
  }),

  link: (theme) => ({
    pt: 2,
    color: colors.blue,
    // fontSize: "10px",
    // fontWeight: "normal",
    textDecoration: "none",
    "&:hover": {
      color: colors.blue,
    },
  }),
};
