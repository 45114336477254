import React from "react";
import { styles } from "./flying_card";
import { Box, Button } from "@mui/material";
import { colors } from "../../utils/const";
import Link from "next/link";
import { useRouter } from "next/router";
import en from "../../../locales/en";
import ar from "../../../locales/ar";
import { arFont, enFont } from "@/styles/font";
import Image from "next/image";

export default function FlyingCard2({ setAnchorEl, toAr, toEn, LogOut }) {
  const router = useRouter();
  const { locale } = router;
  const t = locale === "en" ? en : ar;
  const profPaths =
    router.pathname === "/contract" ||
    router.pathname === "/profile" ||
    router.pathname === "/customerservices";
  return (
    <>
      <Box
        dir={locale === "en" ? "ltr" : "rtl"}
        sx={{
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          borderRadius: "5px",
          bgcolor: colors.white,
          transition: "1s",
          zIndex: 100,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          // position: "relative",
          // bottom: "5px",
        }}
        className={locale == "en" ? enFont.className : arFont.className}
        style={{
          width: locale === "en" ? "240px" : "180px",
          overflow: "hidden",
          // width: "240px",
        }}
      >
        <Image
          src="https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/line.png"
          alt="drop down line"
          width={100}
          height={5}
          style={{ width: "100%", height: "5px" }}
        />
        <Box
          sx={{
            p: { xs: 1, md: 2 },
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Box component={Link} href="/profile" sx={styles.tab} style={{}}>
            {t.userNav.profile}
          </Box>
          <Box sx={{ ...styles.line, my: { xs: 0.8, md: 1 } }}></Box>
          <Box component={Link} href="/contract" sx={styles.tab} style={{}}>
            {t.userNav.your_installement}
          </Box>
          <Box sx={{ ...styles.line, my: { xs: 0.8, md: 1 } }}></Box>
          <Box
            component={Link}
            href="/customerservices"
            sx={styles.tab}
            style={{}}
          >
            {t.userNav.call_center}
          </Box>
          <Box sx={{ ...styles.line, my: { xs: 0.8, md: 1 } }}></Box>
          <Box
            component={Link}
            href="/"
            onClick={() => {
              setAnchorEl(null);
              LogOut();
              localStorage.removeItem("bro-to");
              // store.dispatch(userAction.update({ key: "name", val: "" }));
              // store.dispatch(userAction.update({ key: "avatar", val: "" }));
            }}
            sx={styles.tab}
            style={{}}
          >
            {t.userNav.logout}
          </Box>
          {profPaths && (
            <>
              <Box sx={{ ...styles.line, my: { xs: 0.8, md: 1 } }}></Box>
              <Box
                component={Button}
                // href="/"
                // onClick={() => {
                //   setAnchorEl(null);
                // }}
                onClick={locale === "en" ? toAr : toEn}
                sx={{ ...styles.tab, p: 0, minWidth: "auto" }}
                style={{}}
              >
                {t.main_nav.lang}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
