export const styless = {
  dropBox: {
    color: "#344054",
    borderRadius: "8px",
    textAlign: "center",
    padding: { xs: "4px 8px", md: "8px 20px " },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
};
